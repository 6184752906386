<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Client Submissions</v-card-title>
          <v-card-subtitle>Submissions that we have received from the client form on the main website.</v-card-subtitle>
          <v-card-text>
            <TableCrudComponent endpoint="/api/client-submission" table-name="Client Submissions" :withCampaignData="true"></TableCrudComponent>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>Update GCLID Spreadsheet</v-card-title>
          <v-card-subtitle>Click the button to update the spreadsheet with the latest GCLID submissions</v-card-subtitle>
          <v-card-text>
            <v-btn color="primary" :loading="loading" @click="getGclidSpreadsheet">Update GCLID Spreadsheet</v-btn>
            <p>{{gclidResult}}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableCrudComponent from "@/components/tables/TableCrudComponent";
import axios from "axios";
export default {
  name: "ClientSubmissions",
  components: {TableCrudComponent},

  data() {
    return {
      loading: false,
      gclidResult: []
    }
  },

  methods: {
    getGclidSpreadsheet() {
      let self = this
      this.loading = true
      axios.get(`${this.$store.state.apiUrl}/api/sheets`, this.$store.state.headers)
          .then(function (response) {
            self.loading = false
            self.gclidResult = response.data
          })
          .catch(function (response) {
            self.loading = false
            self.gclidResult = response
          })
    }
  }
}
</script>

<style scoped>

</style>
